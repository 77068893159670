import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Pricing from '../../../components/pricing';
import PricingText from '../../../components/pricing.text';

import imgSEO from '../../../assets/img/heroes/social_branding.jpg';
import SimilarCard from '../../../components/similar.card';
import similar1 from '../../../assets/img/heroes/thumbnails/social_ads.jpg';
import similar2 from '../../../assets/img/heroes/thumbnails/remarketing.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/newsletter.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/social_branding.svg';
import contentPic from '../../../assets/img/illustrations/social_expert.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Social Branding</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Aujourd'hui, aller sur les réseaux sociaux est l’une des premières choses que l’on fait le
          matin, être présent sur les réseaux sociaux est donc indispensable pour une entreprise qui
          veut être proche de ses consommateurs.
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right', textJustify: 'none' }}>
        <Row>
          <Col md={6} className={pageStyle.flex_v_align}>
            <p style={{ textAlign: 'right' }}>
              Le social branding est un moyen de communication efficace permettant à une entreprise
              de partager ses idées et d’ancrer son image de marque dans la tête du consommateur.
            </p>
            <p style={{ textAlign: 'right' }}>
              MDS Digital Agency peut vous aider à développer votre image de marque sur les réseaux
              sociaux en vous proposant un service complet adapté à vos besoins.
            </p>
          </Col>
          <Col style={{ paddingLeft: '50px' }} md={6}>
            <img src={contentPic} alt="" />
          </Col>
        </Row>
      </Grid>

      <Grid>
        <Row>
          <Col md={4}>
            <Pricing title="Pack 1">
              <PricingText>1 réseau social</PricingText>
              <PricingText>1 publication/semaine</PricingText>
              <PricingText>
                Gestion des commentaires et messages privés une fois par semaine
              </PricingText>
            </Pricing>
          </Col>
          <Col md={4}>
            <Pricing title="Pack 2">
              <PricingText>2 réseaux sociaux</PricingText>
              <PricingText>2 publications/semaine</PricingText>
              <PricingText>
                Gestion des commentaires et messages privés une fois par semaine
              </PricingText>
            </Pricing>
          </Col>
          <Col md={4}>
            <Pricing title="Pack 3">
              <PricingText>3 réseaux sociaux</PricingText>
              <PricingText>3 publications/semaine</PricingText>
              <PricingText>
                Gestion des commentaires et messages privés une fois par semaine
              </PricingText>
            </Pricing>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Pricing title="Options">
              <PricingText>Boost publicitaire sur vos publications</PricingText>
              <PricingText>Shooting photo</PricingText>
              <PricingText>Création de la page sur le réseau social</PricingText>
              <PricingText>Création d'une charte graphique</PricingText>
              <PricingText>Abonnement banque d'images</PricingText>
              <PricingText>Publications supplémentaires</PricingText>
            </Pricing>
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Combien me coûterait la gestion de mes réseaux sociaux ? "
        subtitle="Ne restez plus sans réponse"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Social Ads"
            url="/services/reseaux-sociaux/social-ads/"
            img={similar1}
          />
          <SimilarCard
            text="Remarketing"
            url="/services/reseaux-sociaux/remarketing/"
            img={similar2}
          />
          <SimilarCard text="Newsletter" url="/services/newsletter/" img={similar3} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
